import React from "react";

import Layout from "../components/Layout";
import data from "../../content/resources/services.json"
import {Link} from "gatsby";

const Services = () => (
    <Layout>
        <div id="main">
            <div className="services_section_banner">
                <div className="services_banner">
                    <h1>Servicios</h1>
                </div>
            </div>
            <div className="inner">
                <section id="services">
                    <div className="services_section_1">
                        <p className="about_section_1__paragraph">
                            México es uno de los países más complejos a nivel global en el tema de cumplimiento de
                            obligaciones fiscales y contables, debido a que ha venido adoptando una serie de controles y
                            revisiones contables de forma electrónica con estándares muy altos y esto significa un reto
                            muy importante para avanzar tecnológicamente, sin embargo, para los contribuyentes se ha
                            convertido en un tema aún mas complejo. Es por esto que más personas físicas y morales
                            requieren el apoyo de expertos que les permitan cumplir con sus obligaciones tributarias de
                            una manera segura y confiable para así evitar cualquier sanción ante el incumplimiento de
                            estas. Así como también aprovechar cada uno de los beneficios fiscales que la autoridad
                            otorga dentro del marco legal en materia tributaria. En este contexto nuestros servicios se
                            orientan al cumplimiento de dichas obligaciones y al aprovechamiento de las facilidades
                            tributarias que nos otorgan nuestras autoridades hacendarias, para poder operar de una
                            manera exitosa
                        </p>
                    </div>
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Nuestros servicios</h1>
                    </div>
                    <section className="tiles">
                        {data.map((service) => {
                            return (
                                <article key={service.id} className="style1">
                                    <span className="image">
                                        <img
                                            src={service.image}
                                            alt={service.alt}/>
                                    </span>
                                    <Link to={service.path} state={{service: service}}>
                                        <h2>{service.name}</h2>
                                        <div className="content">
                                            <p>
                                                {service.description}
                                            </p>
                                        </div>
                                    </Link>
                                </article>
                            )
                        })}
                    </section>
                </section>
            </div>
        </div>
    </Layout>
);

export default Services;
